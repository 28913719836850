.App {
    text-align: center;
}

.nav-link {
    color: rgb(1, 88, 255);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -2px;

    display: inline-block;
    position: relative;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    bottom: 5px;
    left: 0;
    background-color: rgb(0, 45, 132);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav-link:hover {
    color: rgb(0, 45, 132);
}

.nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.buy {
    color: rgb(255, 129, 46);
    text-decoration: none;
}

.buy::after {
    background-color: rgb(138, 70, 26);
}

.buy:hover {
    color: rgb(138, 70, 26);
}

.main-text {
    color: rgb(1, 88, 255);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -2px;
    animation: fadeIn 2s;
}

.fade-in {
    animation: fadeIn 2s;
}

.nav-link {
    white-space: nowrap;
}

.fade-bg {
    background: rgba(255, 255, 255, 0);
    border-radius: 11px;
    transition: background-color 500ms;
}

@media (max-width: 1200px) {
    .fade-bg {
        background: rgba(200, 200, 200, 0.5);
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}